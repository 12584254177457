import {CalculatorOperatorsRepository} from "../../repository/api/calculate-operators/calculate-operatotors";
import {actionCreators} from "../../state";

export class CalculatorOperatorsUseCases {
    private api: CalculatorOperatorsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: CalculatorOperatorsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public GetSpeed = async (dateStart: string, dateEnd: string, page?: string, perPage?: string) => {
        return await this.api.GetSpeed(dateStart, dateEnd, page, perPage)
    }

    public DownloadTable = async (dateStart: string, dateEnd: string) => {
        return await this.api.DownloadTable(dateStart, dateEnd)
    }
}