import axios from "axios";

import { Configuration, OperatorsApi } from "../generated/callApi";

export class CalculatorOperatorsRepository {
    private service: OperatorsApi;

    constructor() {
        this.service = new OperatorsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL);
    }

    async GetSpeed(dateStart: string, dateEnd: string, page?: string, perPage?: string) {
        try {
            let response = await this.service.apiOperatorReportSpeedGet(dateStart, dateEnd, page, perPage,  { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DownloadTable(dateStart: string, dateEnd: string) {
        try {
            let response = await this.service.apiOperatorReportExcelGet(dateStart, dateEnd, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}