import {Key, useContext, useEffect, useState} from "react";

import {Menu} from "../../components/menu";
import {Link} from "react-router-dom";
import {UseCasesContext} from "../../../context/useCases";
import {TableRow} from "./tableRow";
import {OperatorCount} from "../../../repository/api/generated/callApi"
import {OperatorSpeedProcessing} from "../../../repository/api/generated/callApi"
import Paginator from "../../components/paginator/paginator";


export const CalculateOperators = () => {

    const [report, setReport] = useState<OperatorSpeedProcessing[]>([])
    const [pageCount, setPageCount] = useState<number>(0)
    const [page, setPage] = useState(0)
    const [countOnPage, setCountOnPage] = useState(50)

    const getYesterday = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return date
    }

    const [filter, setFilter] = useState<{
        from: Date,
        to: Date,
    }>({
        from: getYesterday(),
        to: getYesterday(),
    })

    let useCases = useContext(UseCasesContext)


    const formatDateForInput = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' + dd;
    }

    const formatDateMonthAndDay = (day: Date) => {
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm;
    }

    const getStringDate = (date: Date) => {
        let yyyy = date.getFullYear();
        let mm = (date.getMonth() + 1).toString(); // Months start at 0!
        let dd = date.getDate().toString();

        if (mm.length == 1) mm = '0' + mm;
        if (dd.length == 1) dd = '0' + dd;

        return dd + '.' + mm + '.' + yyyy;
    }

    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (filter) {
            setFilter({
                ...filter,
                [e.target.name]: new Date(e.target.value)
            })
        }
    }

    const downloadFile = async () => {
        if (filter) {
            let file = await useCases?.calculatorOperatorsUseCases.DownloadTable(getStringDate(filter?.from), getStringDate(filter?.to))

            if (file instanceof Error) {

            } else {
                console.log(file)
                const link = document.createElement('a');
                if (file?.file) {
                    link.href = file.file;
                }

                link.target = "_blank"
                link.download = "speedreport_" + formatDateMonthAndDay(filter.from) + "-" + formatDateMonthAndDay(filter.to) + ".xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    const readTimeReport = async () => {
        return useCases?.calculatorOperatorsUseCases.GetSpeed(getStringDate(filter?.from), getStringDate(filter?.to), (page+1).toString(), countOnPage.toString());
    }

    useEffect(() => {
        readTimeReport().then((r) => {
            if(!(r instanceof Error)) {
                setReport(r?.speed_processing ?? [])
                setPageCount(r?.total_count ?? 0)
            }
        })
    }, [filter, page, countOnPage])

    return (
        <div className="layout-page">
            <Menu/>
            <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">

                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span
                                className="text-muted fw-light">Обработка на 1 оператора /</span> Отчёт</h4>
                        </div>

                        <div className="col-sm-12 col-md-8">
                            <div
                                className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"*"} className="dt-button add-new btn btn-primary mb-3 mb-md-0"
                                          onClick={(e) => {
                                              e.preventDefault();
                                              downloadFile()
                                          }}><span>Скачать</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-4" style={{zIndex: 800}}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="form-label">Дата от:</label>
                                        <input type="date"
                                               value={filter?.from ? formatDateForInput(filter?.from!) : undefined}
                                               name="from" onChange={dateChange}
                                               className="form-control dt-input dt-full-name"/>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">Дата по:</label>
                                        <input type="date"
                                               value={filter?.to ? formatDateForInput(filter?.to!) : undefined}
                                               name="to" onChange={dateChange}
                                               className="form-control dt-input dt-full-name"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                            <Paginator allCount={pageCount!} page={page} setPage={(page: number) => setPage(page)}
                                       countOnPage={countOnPage}
                                       setCountOnPage={(count: number) => setCountOnPage(count)}/>
                        </div>

                        <div className="card">
                            <div className="table-responsive text-nowrap table_time">
                                <table className="table mt-1">
                                    <thead>
                                    <tr>
                                        <th>Сервис</th>
                                        <th>Траффик</th>
                                        <th>Скорость</th>
                                    </tr>
                                    </thead>
                                    <tbody className="table table-hover">

                                    {
                                        report && report?.map((line: any, index: number) => {
                                            return <TableRow key={index} row={line}/>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>{Array.isArray(report)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}