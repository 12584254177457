// import { useRef, useState } from "react";
// import { Eye, Minus, Plus } from 'tabler-icons-react'
// import { useParams } from "react-router-dom";
// import { ReportReportGroup } from "../../../../repository/api/generated/timeApi";
import { OperatorCount } from "../../../repository/api/generated/callApi"
export const TableRow = (props: { row: any }) => {

    return (
        <>
            <tr>
                <td><span>{props.row.task_name}</span></td>
                <td><span>{props.row.total_records}</span></td>
                <td><b>{ props.row.average_per_operator }</b></td>
            </tr>
        </>
    )
}