import { Auth } from "../repository/api/auth/auth"
import { CallsRepository } from "../repository/api/call/call"
import { AtcsRepository } from "../repository/api/occupancy_service/atc/atc"
import { DirectionsRepository } from "../repository/api/occupancy_service/direction/direction"
import { ReportRepository } from "../repository/api/occupancy_service/report/taskreport"
import { SettingsRepository } from "../repository/api/occupancy_service/settings/settings"
import { TaskSettingsRepository } from "../repository/api/occupancy_service/tasksettings/tasksettings"
import { UpdatesRepository } from "../repository/api/occupancy_service/update/update"
import { TimeReportRepository } from "../repository/api/time/time"
import { UsersRepository } from "../repository/api/user/user"
import { CalculatorOperatorsRepository } from "../repository/api/calculate-operators/calculate-operatotors";

import { AuthorizationUseCases } from "../useCase/authorization/authorization"
import { CallUseCases } from "../useCase/call/call"
import { AtcUseCases } from "../useCase/occupancy_service/atc/atc"
import { DirectionUseCases } from "../useCase/occupancy_service/direction/direction"
import { ReportUseCases } from "../useCase/occupancy_service/report/report"
import { SettingsUseCases } from "../useCase/occupancy_service/settings/settings"
import { TaskSettingsUseCases } from "../useCase/occupancy_service/tasksettings/tasksettings"
import { UpdateUseCases } from "../useCase/occupancy_service/update/update"
import { TimeUseCases } from "../useCase/time/time"
 
import { UserUseCases } from "../useCase/users/user"

import { CalculatorOperatorsUseCases } from "../useCase/calculate-operators/calculate-operators";


export const useUseCases = (actions:any) => {
    const authUseCase = new AuthorizationUseCases(new Auth(), actions)
    const userUseCase =  new UserUseCases(new UsersRepository(), actions)
    const callUseCase = new CallUseCases(new CallsRepository(), actions)
    const timeUseCase = new TimeUseCases(new TimeReportRepository(), actions)

    const atcUseCase = new AtcUseCases(new AtcsRepository(), actions) 
    const directionUseCase = new DirectionUseCases(new DirectionsRepository(), actions) 
    const settingsUseCase = new SettingsUseCases(new SettingsRepository(), actions) 
    const tasksettingsUseCase = new TaskSettingsUseCases(new TaskSettingsRepository(), actions) 
    const updateUseCase = new UpdateUseCases(new UpdatesRepository(), actions)
    const reportUseCase = new ReportUseCases(new ReportRepository(), actions)
    const calculatorOperatorsUseCases = new CalculatorOperatorsUseCases(new CalculatorOperatorsRepository(), actions)

    return {authUseCase, userUseCase, callUseCase, timeUseCase, atcUseCase, directionUseCase, settingsUseCase, tasksettingsUseCase, updateUseCase, reportUseCase, calculatorOperatorsUseCases}
}